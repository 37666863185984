/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "~@ng-select/ng-select/themes/material.theme.css";
@import 'material-icons/iconfont/material-icons.css';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.titulo{
  margin: 0px !important;
}

.titulo-top{
  margin: 0px !important;
}



*, ::before, ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: none;
  border-color: #E5E7EB;
}


::-webkit-scrollbar {
-webkit-appearance: none;
}
::-webkit-scrollbar {
width: 10px;
}

::-webkit-scrollbar-track {
background: #d8d8d8;
border:1px solid #d8d8d8;
}

::-webkit-scrollbar-thumb {
background: #955e1b;
border:1px solid #955e1b;
height:100px;
border-radius:5px;
}

::-webkit-scrollbar-thumb:hover {
background: #b0c950;
	border:1px solid #b0c950;
}


html {
  scrollbar-color: #955e1b grey;
  scrollbar-width: thin;
}

.p-sidebar-header{
  display: none !important;
}

.p-sidebar-footer{
  display: none !important;
}

.p-sidebar-content{
  padding: 0px !important;
}


.p-sidebar-top{
  height: 8.5rem !important;
}

.observacoes{
  height:13rem !important;
}

.procurar{
  height:5rem !important;
}

.p-button{
  border: 0px solid !important;
  background: #7a201e00 !important;
}

.p-button:hover{
  border: 0px solid !important;
  background-color: #7a201e00 !important;
}

.p-button:focus{
  border: 0px solid #7a201e00 !important;
  background-color: #7a201e00 !important;
  outline: 0px;
}


.p-sidebar-left{
width: 16rem !important;
}


.navbar{
  z-index: 1150 !important;
}




mat-datepicker-toggle{
  margin-left: -10px !important;
}

.mat-calendar-body-selected{
  background-color: #b0c950 !important;
}

.mat-calendar-body-ripple{
  background-color: #b0c950 !important;
}

.mat-datepicker-toggle-active{
  color: #b0c950 !important;
}

.mat-focus-indicator:hover{
  background-color: #41462e !important;
  transition-timing-function: ease-out;
  transition: 0.2s;
}

.mat-calendar-body-today{
  background-color: #41462e !important;
}


[hidden] {
  display: none !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight{
  border-color: #b0c950 !important;
  background: #b0c950 !important;
}

.p-radiobutton:hover{
  border-color: #b0c950 !important;
}

.input_field{
  scale: 1;
}


.mat-calendar-body-today{
  background-color: #b0c950 !important;
}


.mat-calendar-body-cell-content:hover{
  background-color: #b0c950 !important;
}

.top-bar{
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  padding-left: 11vw;
}

.top-bar-ent-sai{
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  padding-left: 11vw;
}


.second-top-bar{
  position: fixed;
  top: 70px;
  z-index: 10;
  width: 100%;
}


.content{
  padding-top: 200px;
}


.second-content{
  padding-top: 100px;
}
.cdk-overlay-pane {
  top: 130px;
}
.mat-input-element {
 /*margin-top: 10px;*/
  margin: initial;
}
.mat-select-panel-wrap {
background-color: beige;
}
